/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobileStaging = {
  aws_project_region: "eu-west-1",
  aws_appsync_graphqlEndpoint:
    "https://4rycjjhnmvemjbdeiivlvov5bi.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-xhkmwsvtorh7zd7qgiogyk675u",
};

const awsmobileMaster = {
  aws_project_region: "eu-west-1",
  aws_appsync_graphqlEndpoint:
    "https://sy7yjzrktbedtdbjzpevrq4lu4.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-fwwakcxtqrhybpq7kk75g2y3be",
};

export default process.env.VUE_APP_USER_BRANCH === "master"
  ? awsmobileMaster
  : awsmobileStaging;
