var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.plays,"show-expand":"","single-expand":"","items-per-page":20,"footer-props":{
          'items-per-page-options': [10, 20, -1],
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Giocate")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Importa giocate ")])]}}]),model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Importa giocate")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-radio-group',{model:{value:(_vm.updateOrSkip),callback:function ($$v) {_vm.updateOrSkip=$$v},expression:"updateOrSkip"}},[_c('v-radio',{attrs:{"label":"Ignora giocate già esistenti","value":"skip"}}),_c('v-radio',{attrs:{"label":"Sovrascrivi se già esistenti","value":"update"}})],1),_c('v-file-input',{attrs:{"accept":".xml","small-chips":"","counter":"","multiple":"","show-size":"","truncate-length":"15"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.importing)?_c('v-progress-linear',{staticClass:"no-transition",attrs:{"height":"25"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.progress))+"%")])]):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.importing},on:{"click":_vm.importPlays}},[_vm._v(" Importa ")])],1)],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('questions',{attrs:{"questions":item.questions,"gameID":item.id}})],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.selectedRow.length === 0,"color":"error"},on:{"click":function($event){_vm.deleteDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")]),_vm._v(" Elimina giocate selezionate ")],1)]}}]),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Elimina giocate")])]),_c('v-card-text',[_vm._v(" Sei sicuro di voler eliminare le giocate selezionate ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.deletePlaysLoading},on:{"click":_vm.deletePlays}},[_vm._v(" Elimina ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }